
export class Print {

    constructor(selector, options = {}) {
        const printEl = document.querySelector(`${selector}`);

        if (!printEl) {
            return "Cold not find element with this selector";
        }

        this.printEl(printEl, options);
    }

    printEl(printEl, options) {

        // Generate css links 
        let cssLinkEls = "";
        const cssUrls = options?.css.split(", ");
        for (const cssUrl of cssUrls) {
            cssLinkEls += `<link rel="stylesheet" id="" href="${cssUrl}" media="all"></link>`;
        }

        const inputs = printEl.querySelectorAll("input");
        const inpValues = [];
        for(const inp of inputs) {
            inpValues.push(inp.value);
        }

        const content = printEl.innerHTML;
        const popupWin = window.open('', '_blank', 'width=1400,height=800');
        popupWin.document.open();
        popupWin.document.write(`
            <html> 
            <head>
              ${cssLinkEls}
              <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet">
            </head>
            <body onload="window.print()">
                ${content}               
            </body>
            </html>`);


        if (options.setPageSize) {
            const stylePageSize = document.createElement('style');
            //stylePageSize.innerHTML = `@page {size: ${options.setPageSize}}`;
            stylePageSize.innerHTML = `@page {size: landscape}`;
            popupWin.document.querySelector("head").appendChild(stylePageSize);
        }
            
        const printInputs = popupWin.document.querySelectorAll("input");
        for(let i = 0; i < printInputs.length; i++) {
            printInputs[i].value = inpValues[i];
        }
        popupWin.document.close();

    }

    // just create the style tag and set the page size
    setPageSize(cssPageSize) {
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${cssPageSize}}`;
        document.head.appendChild(style);
    }
}