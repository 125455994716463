window.addEventListener('DOMContentLoaded', event => {

    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    const adminContent = document.querySelector(".admin-content-col");

    
    if (sidebarToggle) {
        // Uncomment Below to persist sidebar toggle between refreshes
        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
            document.body.classList.toggle('sb-sidenav-toggled');
        }
        
        sidebarToggle.addEventListener('click', event => {
           event.preventDefault();

           const sideBar =  document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));

            if(sideBar){
                setToFull();    
            }
            else{
                adaptWidth();
            }
        });
    }

    setToFull = () => {
        adminContent.classList.remove("col-md-9", "col-lg-10","col-xl-10");
        adminContent.classList.add("col");
    }

    adaptWidth = () => {
        adminContent.classList.remove("col");
        adminContent.classList.add("col-md-9", "col-lg-10","col-xl-10");
    }

});

const jsGoBackButtins = document.querySelectorAll(".go-back");

for(const Btn of jsGoBackButtins) {
    Btn.addEventListener('click', (e) => {
        history.back();
        // console.log('back');
    });
}