;(() => {
    const accordionOpeners = document.querySelectorAll(".js-accordion-head");

    for(let accordion of accordionOpeners) {

        accordion.addEventListener("click", toggleAccordion) 
    }

    function toggleAccordion(e) {
        const trigger = e.currentTarget;

        const aBody = trigger.querySelector(".js-accordion-body");
        
        aBody.classList.toggle("invisible");
        aBody.classList.toggle("h-0");
    }
})();
