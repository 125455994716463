// import * as toastr from "toastr";

class Template {

	constructor(selector) {
		this.selector = selector;
		const el = document.querySelector(`${this.selector}`);

		if (!el) {
			return;
		}
		
		// Call call methods
		return Object.getOwnPropertyNames (Object.getPrototypeOf (this)) 
		.filter(propName => (propName !== 'constructor' && typeof this[propName] === 'function'))
        .forEach(propName => this[propName]());

	}

	toggleNav() {
		const navToggler = document.querySelector(".navbar-toggler");
		navToggler.addEventListener("click", () => {
			document.querySelector("#navbarSupportedContent").classList.toggle("collapse");
		});
	}

	dropDownsToggle() {
		const dropdownToggleres = document.querySelectorAll(".dropdown-toggle");

		for(let dt of dropdownToggleres) {
			dt.addEventListener("click", toggleDropdown);
		}

		function toggleDropdown(e) {
			const triggerTarget = e.currentTarget.getAttribute("data-target");
			const infoToggler = document.querySelector(`#${triggerTarget}`);
			infoToggler.classList.toggle("dropdown-menu");

		}
	}
}
new Template("#navMain");


