function animations() {
    let elements = document.querySelectorAll('.animation');
    if(!elements) {
        return;
    }
    let elementTop;
    let windowTop;
    let elementBottom;
    let windowBottom
    for (let i = 0; i < elements.length; i++) {
        thisAnimationClass = elements[i].getAttribute('data-animation');
        elementTop = elements[i].offsetTop;
        windowTop = document.documentElement.scrollTop;
        elementBottom = elements[i].offsetTop + elements[i].offsetHeight;
        windowBottom = window.innerHeight + window.scrollY;

        if (elementTop > windowTop && windowBottom > elementBottom-window.screen.availHeight - 100) {
            elements[i].classList.add(thisAnimationClass);
        }
        else {
            elements[i].classList.remove(thisAnimationClass);
        }

    }
}
animations();
window.addEventListener("scroll", function(){
    animations();
});
