export class Utility {

    insertAfter(newNode, existingNode) {
        existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
    }

    disableElement(e) {
        const trigger = e.currentTarget;
        document.querySelector(`${trigger.getAttribute('data-disable')}`).disabled = trigger.checked;
    }

    submitCurrentForm(e) {
        const currentForm = e.target.closest("form");
        if (!currentForm.checkValidity()) {
            return currentForm.reportValidity();
        }

        currentForm.submit();
    }

    openUrl(url) {
        location.href = `/${url}`;
    }

    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(function () { callback.apply(this, args); }, wait);
        };
    }

    isValidDate (date) {
        return date instanceof Date && !isNaN(date);
    }

}
